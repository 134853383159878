<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-patient-bar @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-card>
          <v-appointment :hn="currentPatient.hn"></v-appointment>
        </v-card>
      </v-col>
      <v-col cols="12" v-else>
        <v-model-table model-name="Appointments" model-title="รายการนัดหมาย"
          :model-api="['models','Appointment','Appointments']"
          :model-api-search="appointmentQuery"
          api-with="patient"
          :headers="appointmentHeaders" :insertable="false" expandable>
          <template v-slot:toolBarItem>
            <v-date-field
              v-model="currentAppointmentDate"
              label="วันที่รับบริการ"
              class="px-1"
              single-line
              hide-details
            ></v-date-field>
            <v-master-field v-model="currentClinic" :custom-api="['models','Master','Clinics','query']"
              custom-api-text="clinicName" custom-api-value="clinicCode"
              customApiEagerLoad
              class="pr-1" clearable
              label="คลินิก" 
              single-line hide-details
              show-code></v-master-field>
            <v-master-field v-model="currentDoctorCode" :custom-api="['models','Master','Doctors','query']"
              custom-api-text="nameTH" custom-api-value="doctorCode"
              customApiEagerLoad
              class="pr-1" clearable
              label="แพทย์" 
              single-line hide-details
              show-code></v-master-field>
          </template>
          <template v-slot:toolBarSearch>
            <v-text-field
              class="pr-2"
              v-model="currentAppointmentFilter"
              label="Search"
              append-icon="search"
              single-line
              hide-details
            ></v-text-field>
          </template>
          <template v-slot:item.patient.name_th="props">
            <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
          </template>
          <template v-slot:item.appointmentDateTime="props">
            <v-label-datetime :date-time="props.item.appointmentDateTime" short-date-time></v-label-datetime>
          </template>
          <template v-slot:item.appointmentType="props">
            <v-label-master group-key="appointmentType" :item-code="props.item.appointmentType"></v-label-master>
          </template>
          <template v-slot:item.appointmentActivity="props">
            <v-label-master group-key="appointmentActivity" :item-code="props.item.appointmentActivity"></v-label-master>
          </template>
          <template v-slot:item.showStatus="props">
            <v-icon
              color="green darken-3"
              v-if="props.item.to_encounter && props.item.to_encounter.length>0"
            >mdi-check-circle-outline</v-icon>
          </template>
          <template v-slot:expanded-item="{ headers,item,actions }">
            <td :colspan="headers.length" class="py-0">
              <v-row>
                <v-col cols="12" sm="4" md="2" class="font-weight-bold">คำแนะนำ</v-col>
                <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.suggestion || "-" }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="2" class="font-weight-bold">บันทึกภายใน</v-col>
                <v-col cols="12" sm="8" md="10" style="white-space: pre-line;">{{ item.note || "-" }}</v-col>
              </v-row>
              <v-row v-if="item.from_encounter">
                <v-col cols="12" class="font-weight-bold">ประวัติการรักษาครั้งก่อนหน้า</v-col>
                <v-col cols="12">
                  <v-document-list
                    :hn="item.from_encounter.hn"
                    :encounterId="item.from_encounter.encounterId"
                    folder="default"
                    flat
                  ></v-document-list>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patientLoader from '@/components/mixins/patientLoader'
import helperDateTime from 'tantee-common/lib/helperDateTime';
import { isNil } from "lodash";

export default {
  mixins: [patientLoader],
  data: ()=>({
    currentAppointmentDate: undefined,
    currentAppointmentFilter: undefined,
    currentClinic: undefined,
    currentDoctorCode: undefined,
    appointmentHeaders: [
      {
        text: "",
        value: "showStatus",
        class: "body-2",
        width: "50px",
      },
      {
        text: "เวลานัดหมาย",
        value: "appointmentDateTime",
        class: "body-2",
      },
      {
        text: "HN",
        value: "hn",
        class: "body-2",
      },
      {
        text: "ชื่อ-นามสกุล",
        value: "patient.name_th",
        class: "body-2",
      },
      {
        text: "คลินิก",
        value: "clinic.clinicName",
        class: "body-2",
      },
      {
        text: "แพทย์",
        value: "doctor.nameTH",
        class: "body-2",
      },
      {
        text: "ชนิดการนัด",
        value: "appointmentType",
        class: "body-2",
      },
      {
        text: "กิจกรรม",
        value: "appointmentActivity",
        class: "body-2",
      },
    ],
  }),
  computed: {
    appointmentQuery() {
      let result = {
        "whereDate#appointmentDateTime": this.currentAppointmentDate,
      };
      if (!isNil(this.$config("location").locationCode)) {
        result["clinic$locationCode"] = this.$config("location").locationCode
      }
      if (!isNil(this.currentAppointmentFilter)) {
        result["where#hn#like"] = this.currentAppointmentFilter + "%"
      }
      if (this.currentClinic) result['clinicCode'] = this.currentClinic
      if (this.currentDoctorCode) result['doctorCode'] = this.currentDoctorCode

      return result;
    },
  },
  beforeMount() {
    this.currentAppointmentDate = helperDateTime.today().format("YYYY-MM-DD")
  }
}
</script>

<style lang="css" scoped>
</style>
